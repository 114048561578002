import { navigate } from 'gatsby';
import React, { memo, useEffect, useState, useContext } from 'react';
import firebase from 'gatsby-plugin-firebase';
import ShortUniqueId from 'short-unique-id';
import LoadingScreen from '../components/router/LoadingScreen';
import Wrapper from '../components/shared/Wrapper';
import UserContext from '../contexts/UserContext';
import { getUnsplashPhoto } from '../utils';
import initialState from '../data/initialState.json';

const Home = () => {
  const [loading] = useState(true);
  const { user, loginAnonymously } = useContext(UserContext);
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  const createResume = async () => {
    const id = uuid();
    const name = 'My Resume';
    const localUser = JSON.parse(localStorage.getItem('user'));
    console.log(localUser);
    const preview = await getUnsplashPhoto();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    let firstName;
    let lastName;

    const resume = {
      ...initialState,
      id,
      name,
      user: localUser.uid,
      preview,
      profile: {
        ...initialState.profile,
        firstName: firstName || '',
        lastName: lastName || '',
      },
      createdAt,
      updatedAt: createdAt,
    };

    console.log(resume);
    firebase.database().ref(`resumes/${id}`).set(resume);

    return id;
  };

  // const handleRefresh = () => {
  //   if (typeof window !== `undefined`) {
  //     window.location.href = `/`;
  //   }
  // };

  useEffect(() => {
    (async () => {
      if (!user) {
        console.log('start login');
        const u = await loginAnonymously();
        localStorage.setItem('user', JSON.stringify(u.user));
        console.log(u.user);
        console.log('end login');
        console.log('start create resume');
        const id = await createResume();
        console.log('end create resume');
        navigate(`/app/builder/${id}`);
        console.log('navigate builder');
      }
    })();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Wrapper>
      <div className="container px-8 xl:px-0 text-center md:text-left mt-24">
        Resume One Resume Builder
      </div>
    </Wrapper>
  );
};

export default memo(Home);
